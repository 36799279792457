const state = {
  toggleShow: {
    mypageModal: false,
    languageModal: false,
    walletModal: false,
    loginModal: false,
    signupModal: false,
    noticeModal: false,
    rulesModal: false,
    eventModal: false,
    faqModal: false,
    partnershipModal: false,
    customerModal: false,
    couponModal: false,
    messageModal: false,
    bettingModal: false,
    PointModal: false,
    loginWallet: false,
  },
  isShowModal: false,
};

const getters = {};

const mutations = {
  handleToggle(state, payload) {
    state.toggleShow[payload] = !state.toggleShow[payload];
    const check = Object.values(state.toggleShow).filter((e) => e);
    if (check.length === 0) {
      document.querySelector("html").style.overflowY = "auto";
    } else {
      document.querySelector("html").style.overflowY = "hidden";
    }
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
