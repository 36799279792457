import { getPaymentHistoryRequest } from "@/api/payment/request";

const state = {
  paymentHistory: {},
};

const getters = {};

const mutations = {
  handleUpdateListPaymentHistory(state, dispatch) {
    state.paymentHistory = dispatch;
  },
};

const actions = {
  async getPaymentHistoryAction({ commit }, data) {
    const res = await getPaymentHistoryRequest(data);
    if (res.data.status == 0) {
      commit("handleUpdateListPaymentHistory", {
        list: res.data.data,
        pagination: {
          limit: res.data.limit,
          page: res.data.page,
          total: res.data.total,
        },
      });
      commit("handleUpdateLoading", false);
    }
  },
};

export default { state, getters, mutations, actions };
