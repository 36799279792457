import Swal from "sweetalert2/dist/sweetalert2.js";
const state = {
  alerts: [],
};

const getters = {
  alerts(state) {
    return state.alerts;
  },
};

const mutations = {
  SET_ALERT(state, alert) {
    // const Toast = Swal.mixin({
    //   toast: true,
    //   position: "bottom-right",
    //   showConfirmButton: false,
    //   timer: 3000,
    //   timerProgressBar: true,
    //   didOpen: (toast) => {
    //     toast.onmouseenter = Swal.stopTimer;
    //     toast.onmouseleave = Swal.resumeTimer;
    //     toast.addEventListener("click", () => Swal.close());
    //   },
    // });
    // Toast.fire({
    //   icon: alert.type,
    //   text: alert.message,
    //   title: process.env.VUE_APP_SERVICE_TYPE,
    // });
    Swal.fire({
      title: process.env.VUE_APP_SERVICE_TYPE,
      text: alert.message,
      icon: alert.type,
      showConfirmButton: false,
      timer: 3000,
    });
    // alert.idTimeout = setTimeout(() => {
    //   this.commit("REMOVE_ALERT", alert);
    // }, alert.duration || 3000);
    // state.alerts.push(alert);
  },
  REMOVE_ALERT(state, alert) {
    clearTimeout(alert.idTimeout);
    state.alerts.splice(state.alerts.indexOf(alert), 1);
  },
};

const actions = {
  showAlert({ commit }, alert) {
    commit("SET_ALERT", alert);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
// alert {
//     type: 'info', 'error', 'success',
//     message,
//     duration
// }
