import { getCustomPage } from "@/api/customPage/request";

const state = {
  styles: {
    bg_color: "",
    bg_image: "",
    chat_yn: "0",
    footer_banners: "[{\"key\":\"evolution\",\"onoff\":1},{\"key\":\"dreamgaming\",\"onoff\":1},{\"key\":\"asiagaming\",\"onoff\":1},{\"key\":\"aviax\",\"onoff\":1},{\"key\":\"ezugi\",\"onoff\":1},{\"key\":\"pragmatic\",\"onoff\":1},{\"key\":\"bigtimegaming\",\"onoff\":1},{\"key\":\"bota\",\"onoff\":1},{\"key\":\"playtech\",\"onoff\":1},{\"key\":\"skywind\",\"onoff\":1},{\"key\":\"amigogaming\",\"onoff\":1},{\"key\":\"gamzix\",\"onoff\":1},{\"key\":\"onetouch\",\"onoff\":1},{\"key\":\"777\",\"onoff\":1},{\"key\":\"microgaming\",\"onoff\":1},{\"key\":\"redrake\",\"onoff\":1},{\"key\":\"evoplay\",\"onoff\":1},{\"key\":\"bti\",\"onoff\":1},{\"key\":\"booongo\",\"onoff\":1},{\"key\":\"redtiger\",\"onoff\":1},{\"key\":\"netent\",\"onoff\":1},{\"key\":\"nolimitcity\",\"onoff\":1},{\"key\":\"habanero\",\"onoff\":1},{\"key\":\"slotopia\",\"onoff\":1},{\"key\":\"popok\",\"onoff\":1},{\"key\":\"bfgames\",\"onoff\":1},{\"key\":\"dreamtech\",\"onoff\":1},{\"key\":\"pgsoft\",\"onoff\":1},{\"key\":\"playson\",\"onoff\":1}]",
    form_pos: "2",
    logo_image: null,
    logo_pos: "0",
    nav_pos: "1",
    template: "Fortunez",
    game_vendors: "[{\"key\":\"evolution\",\"onoff\":1},{\"key\":\"dreamgaming\",\"onoff\":1},{\"key\":\"asiagaming\",\"onoff\":1},{\"key\":\"aviax\",\"onoff\":1},{\"key\":\"ezugi\",\"onoff\":1},{\"key\":\"pragmatic\",\"onoff\":1},{\"key\":\"bigtimegaming\",\"onoff\":1},{\"key\":\"bota\",\"onoff\":1},{\"key\":\"playtech\",\"onoff\":1},{\"key\":\"skywind\",\"onoff\":1},{\"key\":\"amigogaming\",\"onoff\":1},{\"key\":\"gamzix\",\"onoff\":1},{\"key\":\"onetouch\",\"onoff\":1},{\"key\":\"777\",\"onoff\":1},{\"key\":\"microgaming\",\"onoff\":1},{\"key\":\"redrake\",\"onoff\":1},{\"key\":\"evoplay\",\"onoff\":1},{\"key\":\"bti\",\"onoff\":1},{\"key\":\"booongo\",\"onoff\":1},{\"key\":\"redtiger\",\"onoff\":1},{\"key\":\"netent\",\"onoff\":1},{\"key\":\"nolimitcity\",\"onoff\":1},{\"key\":\"habanero\",\"onoff\":1},{\"key\":\"slotopia\",\"onoff\":1},{\"key\":\"popok\",\"onoff\":1},{\"key\":\"bfgames\",\"onoff\":1},{\"key\":\"dreamtech\",\"onoff\":1},{\"key\":\"pgsoft\",\"onoff\":1},{\"key\":\"playson\",\"onoff\":1}]",
    links: "",
    front_title: "Fortunez",
    is_load: true,
  },
};

const getters = {};

const mutations = {
  handleUpdateStyles(state, payload) {
    state.styles = payload;
    state.styles.is_load = true;
  },
};

const actions = {
  async getStylesAction({ commit }) {
    const res = await getCustomPage();
    try {
      commit("handleUpdateStyles", res?.data);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
