import {
  getMeRequest,
  changeProfileRequest,
  getListUser,
  createUser,
} from "@/api/user/request";
import { convertMoney } from "@/utils";
import { SSRRequest } from "@/api/axios";
import i18n from "@/locales/index";
import { deleteCookie } from "@/utils/cookie";

const state = {
  userInfor: {},
  list: {},
};

const getters = {
  getUserInfor(state) {
    return state.userInfor;
  },
  handleUpdateListUser(state, dispatch) {
    state.listUser = dispatch;
  },
};

const mutations = {
  async updateUserInfor(state, payload) {
    let tempMoney = 0;

    if (
      state?.userInfor?.partnerSettleAmount &&
      state.userInfor.partnerSettleAmount > 0
    ) {
      tempMoney = state.userInfor.partnerSettleAmount;
    }

    state.userInfor = payload;
    if (tempMoney > 0) {
      state.userInfor.partnerSettleAmount = tempMoney;
    }
  },

  resetUser(state) {
    state.userInfor = {};
  },

  handleUpdateListUser(state, dispatch) {
    state.list = dispatch;
  },
};

const actions = {
  async getListUserAction({ commit }, data) {
    const res = await getListUser(data);
    if (res.data.status == 0) {
      commit("handleUpdateListUser", {
        list: res.data.data,
        pagination: {
          limit: res.data.limit,
          page: res.data.page,
          total: res.data.total,
        },
      });
      commit("handleUpdateLoading", false);
    }
  },

  async getMeAction({ commit, dispatch }, data) {
    const res = await getMeRequest(data);
    if (res?.is_success) {
      // if (res.member.me_money == 0) {
      let amount = res?.member?.all_games_amount.reduce(
        (a, b) => a + b.mg_money,
        0
      );
      res.member.me_money += amount;
      // }
      commit("updateUserInfor", res?.member);
    } else {
      if (res?.error_code === 900001) {
        await dispatch("showAlert", {
          type: "error",
          message: i18n.global.t("귀하의 계정이 차단되었습니다"),
        });
        localStorage.removeItem("_slg");
        localStorage.removeItem("mIdx");
        sessionStorage.clear();
        deleteCookie("MDS");
        deleteCookie("CID");
        deleteCookie("mIdx");
        location.reload();
      }
      /*alert(res?.message)*/
    }
  },
  async changeProfileAction({ dispatch }, data) {
    const res = await changeProfileRequest(data);
    if (res?.is_success) {
      dispatch("showAlert", {
        type: "success",
        message: i18n.global.t("changeProfileSuccess"),
      });
      await dispatch("getMeAction");
    } else {
      // console.log(res?.error_code)
      switch (res?.error_code.toString()) {
        case "-10000012" || "-10000008":
          dispatch("showAlert", {
            type: "error",
            message: i18n.global.t("errorCode-10000008"),
          });
          break;
        case "-10000006":
          dispatch("showAlert", {
            type: "error",
            message: i18n.global.t("errorCode-10000006"),
          });
          break;
        case "-100000009":
          dispatch("showAlert", {
            type: "error",
            message: i18n.global.t("errorCode-10000009"),
          });
          break;
        default:
          dispatch("showAlert", {
            type: "error",
            message: i18n.global.t("errorCode-10000007"),
          });
      }
    }
    return res;
  },
  async createUserAction({ commit }, data) {
    const res = await createUser(data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
