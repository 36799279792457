import {
  getListWithDrawRequest,
  withdrawListRealTime,
  withdrawRequest,
  deleteWithDrawRequest,
} from "@/api/withdraw/request";
import {
  convertMoney,
  generateRandomName,
  generateRandomNumber,
} from "../../utils";
import store from "@/store";
import i18n from "@/locales/index";
import { v4 as uuidv4 } from "uuid";

function randomIntFromInterval() {
  return Math.ceil(Math.random() * (300 - 10) + 10) * 1000;
}

function getRandomTimeInDay() {
  var today = new Date();
  var randomHour = Math.floor(Math.random() * (today.getHours() + 1));
  var randomMinute = Math.floor(Math.random() * 60);
  today.setHours(randomHour, randomMinute, 0, 0);
  return today;
}

const state = {
  listWithDraw: null,
  listWithDrawRealTime: [],
  check: false,
};

const getters = {
  getListWithDrawRealTime(state) {
    while (state.listWithDrawRealTime.length < 35) {
      let currentTime = new Date();
      let randomTime = getRandomTimeInDay();
      randomTime = randomTime > currentTime ? currentTime : randomTime;

      state.listWithDrawRealTime.push({
        wi_member_nickname: `*****${uuidv4().slice(-2)}`,
        wi_created: randomTime,
        wi_money: convertMoney(randomIntFromInterval()),
      });
    }
    if (state.listWithDrawRealTime.length % 2 === 0) {
      let currentTime = new Date();
      let randomTime = getRandomTimeInDay();
      randomTime = randomTime > currentTime ? currentTime : randomTime;

      state.listWithDrawRealTime.push({
        wi_member_nickname: `*****${uuidv4().slice(-2)}`,
        wi_created: randomTime,
        wi_money: convertMoney(randomIntFromInterval()),
      });
    }

    return state.listWithDrawRealTime.sort(
      (a, b) => b.wi_created - a.wi_created
    );
  },
  totalAmountWithDraw(state) {
    let total = 0;
    state.listWithDraw?.forEach((e) => (total = total + e.wi_money));
    return total;
  },
};

const mutations = {
  handleUpdateListWithDraw(state, payload) {
    if (state.listWithDraw != null && state.listWithDraw?.length) {
      if (
        state.listWithDraw[0].wi_idx === payload[0]?.wi_idx &&
        state.listWithDraw[0].wi_status !== payload[0]?.wi_status
      ) {
        payload[0]?.wi_status === "COMPLETED"
          ? store.dispatch("showAlert", {
              type: "success",
              message: i18n.global.t("withdrawRequestApproved"), //WITHDRAW REQUEST Approved
            })
          : store.dispatch("showAlert", {
              type: "error",
              message: i18n.global.t("withdrawRequestCanceled"), //WITHDRAW REQUEST CANCELED
            });

        state.check = true;
      }
    }
    state.listWithDraw = [...payload];
  },
  handleUpdateListWithDrawRealTime(state, payload) {
    // state.listWithDrawRealTime = [...payload];
    // state.listWithDrawRealTime = state.listWithDrawRealTime?.filter(
    //   (item) =>
    //     new Date(item?.wi_created).getFullYear() ==
    //       new Date().getUTCFullYear() &&
    //     new Date(item?.wi_created).getMonth() == new Date().getUTCMonth() &&
    //     new Date(item?.wi_created).getDate() == new Date().getUTCDate()
    // );
  },
  handleWithDrawCheck(state, dispatch) {
    state.check = dispatch;
  },
  clearWithDraw(state) {
    state.listWithDraw = null;
    state.check = false;
  },
};

const actions = {
  async getListWithDrawAction({ commit }, data) {
    const res = await getListWithDrawRequest(data);
    if (res?.is_success) {
      commit("handleUpdateListWithDraw", res?.list);
    } else {
      /*alert(res?.message)*/
      commit("handleUpdateListWithDraw", []);
    }
  },
  async withdrawAction({ commit }, data) {
    const res = await withdrawRequest(data);
    return res;
  },
  async withdrawListRealTimeAction({ commit }) {
    const res = await withdrawListRealTime();
    if (res?.is_success) {
      commit("handleUpdateListWithDrawRealTime", res.list);
    }
    // else {
    //    console.log(res?.message);
    // }
  },
  async deleteWithdrawAction({ dispatch }, { idx, data }) {
    const res = await deleteWithDrawRequest(idx, data);
    if (res?.is_success) {
      dispatch("getListWithDrawAction", data);
    }
    // else {
    //    console.log(res?.message);
    // }
    return res;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
