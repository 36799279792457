import { createApp } from "vue";
import App from "./App.vue";
import routes from "./routes";
import store from "@/store";
import VueToastify from "vue-toastify";
import Vue3Marquee from "vue3-marquee";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-blue/theme.css";

import VueLazyload from "vue-lazyload";
//Datepicker
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

//i18n
import i18n from "@/locales";

//sweetalert2
import "sweetalert2/src/sweetalert2.scss";

const vuetify = createVuetify({
  components,
  directives,
});

const loadimage = require("@/assets/fortunez/gifs/loading-image-2.gif");

export const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
  console.error("Global error:", err);
  console.log("Vue instance:", instance);
  console.log("Error info:", info);
};

app.component("VueDatePicker", VueDatePicker);

app
  .use(routes)
  .use(store)
  .use(Vue3Marquee)
  .use(VueToastify)
  .use(i18n)
  .use(VueLazyload, {
    // Tùy chọn của bạn ở đây, ví dụ:
    preLoad: 1.3, // Tải hình ảnh trước khi nó xuất hiện 1.3 lần trong trường nhìn
    error: "path_to_error_image.jpg", // Hình ảnh hiển thị nếu xảy ra lỗi khi tải
    loading: loadimage, // Hình ảnh hiển thị trong quá trình tải
    attempt: 1, // Số lần thử tải lại hình ảnh nếu có lỗi
  })
  .use(PrimeVue)
  .use(vuetify)
  .mount("#app");
