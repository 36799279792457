import { getBankRequest } from "@/api/getBank/request";
// import store from "@/store";
// import { computed } from "vue";

const state = {
  listBank: [],
  listBank2: [],
};

const getters = {
  getBank2: (state) => {
    return state.listBank2;
  },
  getBank: (state) => {
    // let grade = store.state.user.userInfor?.me_grade;
    // console.log(state.listBank.GRADE);
    // return state.listBank[0];
    // if(!grade) return store.state.partnership.data
    // switch (state.listBank) {
    //   case value:

    //     break;

    //   default:
    //     break;
    // }
    let bankVVIP = state.listBank.find((item) => item.GRADE === 4);
    let bankVIP = state.listBank.find((item) => item.GRADE === 3);
    let bankGeneral = state.listBank.find((item) => item.GRADE === 2);
    let bankNew = state.listBank.find((item) => item.GRADE === 1);

    if (bankVVIP) {
      return bankVVIP;
    }

    if (bankVIP) {
      return bankVIP;
    }

    if (bankGeneral) {
      return bankGeneral;
    }

    if (bankNew) {
      return bankNew;
    }
  },
};

const mutations = {
  handleUpdateBank(state, payload) {
    state.listBank = payload;
  },
  handleUpdateBank2(state, payload) {
    state.listBank2 = payload;
  },
};

const actions = {
  async getBankAction({ commit }) {
    const res = await getBankRequest();
    try {
      commit("handleUpdateBank", res?.data);
      commit("handleUpdateBank2", res?.data2);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
