import {
  depositListRealTime,
  depositRequest,
  getListDepositRequest,
  deleteDepositRequest,
} from "@/api/deposit/request";
import {
  convertMoney,
  generateRandomName,
  generateRandomNumber,
} from "@/utils";
import store from "@/store";
import i18n from "@/locales/index";
import { v4 as uuidv4 } from "uuid";

function randomIntFromInterval() {
  return Math.ceil(Math.random() * (300 - 10) + 10) * 1000;
}

function getRandomTimeInDay() {
  var today = new Date();
  var randomHour = Math.floor(Math.random() * (today.getHours() + 1));
  var randomMinute = Math.floor(Math.random() * 60);
  today.setHours(randomHour, randomMinute, 0, 0);
  return today;
}

const state = {
  listDeposit: null,
  listDepositRealTime: [],
  check: false,
};
const getters = {
  getListDepositRealTime(state) {
    while (state.listDepositRealTime.length < 35) {
      let currentTime = new Date();
      let randomTime = getRandomTimeInDay();
      randomTime = randomTime > currentTime ? currentTime : randomTime;

      state.listDepositRealTime.push({
        de_member_nickname: `*****${uuidv4().slice(-2)}`,
        de_created: randomTime,
        de_money: convertMoney(randomIntFromInterval()),
      });
    }
    if (state.listDepositRealTime.length % 2 === 0) {
      let currentTime = new Date();
      let randomTime = getRandomTimeInDay();
      randomTime = randomTime > currentTime ? currentTime : randomTime;

      state.listDepositRealTime.push({
        de_member_nickname: `*****${uuidv4().slice(-2)}`,
        de_created: randomTime,
        de_money: convertMoney(randomIntFromInterval()),
      });
    }

    return state.listDepositRealTime.sort(
      (a, b) => b.de_created - a.de_created
    );
  },
  totalAmountDeposit(state) {
    let total = 0;
    state.listDeposit?.forEach((e) => (total = total + e.de_money));
    return total;
  },
};

const mutations = {
  handleUpdateListDeposit(state, payload) {
    if (state.listDeposit != null && state.listDeposit?.length) {
      if (
        state.listDeposit[0].de_idx === payload[0]?.de_idx &&
        state.listDeposit[0].de_status !== payload[0]?.de_status
      ) {
        payload[0]?.de_status === "COMPLETED"
          ? store.dispatch("showAlert", {
              type: "success",
              message: i18n.global.t("depositRequestApproved"), //DEPOSIT REQUEST SUCCESS
            })
          : store.dispatch("showAlert", {
              type: "error",
              message: i18n.global.t("depositRequestCanceled"), //DEPOSIT REQUEST CANCELED
            });
        state.check = true;
      }
    }
    state.listDeposit = [...payload];
  },
  handleUpdateListDepositRealTime(state, payload) {
    // state.listDepositRealTime = [...payload];
    //   state.listDepositRealTime = state.listDepositRealTime?.filter(
    //     (item) =>
    //       new Date(item?.de_created).getFullYear() ==
    //         new Date().getUTCFullYear() &&
    //       new Date(item?.de_created).getMonth() == new Date().getUTCMonth() &&
    //       new Date(item?.de_created).getDate() == new Date().getUTCDate()
    //   );
  },
  handleDepositCheck(state, dispatch) {
    state.check = dispatch;
  },
  clearDeposit(state) {
    state.listDeposit = null;
    state.check = false;
  },
};

const actions = {
  async getListDepositAction({ commit }, data) {
    const res = await getListDepositRequest(data);
    if (res?.is_success) {
      commit("handleUpdateListDeposit", res?.list);
    } else {
      commit("handleUpdateListDeposit", []);
    }
  },
  async depositAction({ commit }, data) {
    const res = await depositRequest(data);
    return res;
  },
  async depositListRealTimeAction({ commit }) {
    const res = await depositListRealTime();
    if (res?.is_success) {
      commit("handleUpdateListDepositRealTime", res.list);
    } else {
      // console.log(res?.message);
    }
  },
  async deleteDepositAction({ dispatch }, { idx, data }) {
    const res = await deleteDepositRequest(idx, data);
    if (res?.is_success) {
      dispatch("getListDepositAction", data);
    } else {
      // console.log(res?.message);
    }
    return res;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
