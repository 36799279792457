const paths = {
  Fortunez: [
    {
      path: "/",
      name: "MainLayout",
      component: () => import("@/layout/MainLayout/fortunez"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/fortunez/Home"),
        },
        // {
        //   path: "/provider",
        //   name: "Provider",
        //   children: [
        //     {
        //       path: "/provider/newReleases",
        //       name: "New Releases",
        //       component: () => import("@/views/fortunez/Provider/NewReleases"),
        //     },
        //   ],
        // },
        {
          path: "/newReleases",
          name: "New Releases",
          component: () => import("@/views/fortunez/Provider/NewReleases"),
        },
        {
          path: "/hotGames",
          name: "Hot Game",
          component: () => import("@/views/fortunez/Provider/HotGame"),
        },
        {
          path: "/slots",
          name: "Slot",
          component: () => import("@/views/fortunez/Provider/Slot"),
        },
        {
          path: "/liveCasino",
          name: "Live Casino",
          component: () => import("@/views/fortunez/Provider/LiveCasino"),
        },
        {
          path: "/baccarat",
          name: "Baccarat",
          component: () => import("@/views/fortunez/Provider/Baccarat"),
        },
        {
          path: "/blackjack",
          name: "Blackjack",
          component: () => import("@/views/fortunez/Provider/Blackjack"),
        },
        {
          path: "/roulette",
          name: "Roulette",
          component: () => import("@/views/fortunez/Provider/Roulette"),
        },
        {
          path: "/playGame",
          name: "game",
          component: () => import("@/views/fortunez/Game"),
        },
      ],
      meta: {
        title: "Fortunez",
        icon: "favicon.ico",
      },
    },

    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
    // },
    // {
    //     path: '/:pathMatch(.*)*',
    //     redirect: "/404"
    // },
  ],
  Sydney: [
    {
      path: "/",
      name: "MainLayout",
      component: () => import("@/layout/MainLayout/sydney"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/sydney/Home"),
        },
        {
          path: "/newReleases",
          name: "New Releases",
          component: () => import("@/views/sydney/Provider/NewReleases"),
        },
        {
          path: "/hotGames",
          name: "Hot Game",
          component: () => import("@/views/sydney/Provider/HotGame"),
        },
        {
          path: "/slots",
          name: "Slot",
          component: () => import("@/views/sydney/Provider/Slot"),
        },
        {
          path: "/liveCasino",
          name: "Live Casino",
          component: () => import("@/views/sydney/Provider/LiveCasino"),
        },
        {
          path: "/baccarat",
          name: "Baccarat",
          component: () => import("@/views/sydney/Provider/Baccarat"),
        },
        {
          path: "/blackjack",
          name: "Blackjack",
          component: () => import("@/views/sydney/Provider/Blackjack"),
        },
        {
          path: "/roulette",
          name: "Roulette",
          component: () => import("@/views/sydney/Provider/Roulette"),
        },
        {
          path: "/playGame",
          name: "game",
          component: () => import("@/views/sydney/Game"),
        },
      ],
      meta: {
        title: "Sydney",
        icon: "sydney.ico",
      },
    },
  ],
  Ottogi: [
    {
      path: "/",
      name: "MainLayout",
      component: () => import("@/layout/MainLayout/ottogi"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/ottogi/Home"),
        },
        {
          path: "/newReleases",
          name: "New Releases",
          component: () => import("@/views/ottogi/Provider/NewReleases"),
        },
        {
          path: "/hotGames",
          name: "Hot Game",
          component: () => import("@/views/ottogi/Provider/HotGame"),
        },
        {
          path: "/slots",
          name: "Slot",
          component: () => import("@/views/ottogi/Provider/Slot"),
        },
        {
          path: "/liveCasino",
          name: "Live Casino",
          component: () => import("@/views/ottogi/Provider/LiveCasino"),
        },
        {
          path: "/baccarat",
          name: "Baccarat",
          component: () => import("@/views/ottogi/Provider/Baccarat"),
        },
        {
          path: "/blackjack",
          name: "Blackjack",
          component: () => import("@/views/ottogi/Provider/Blackjack"),
        },
        {
          path: "/roulette",
          name: "Roulette",
          component: () => import("@/views/ottogi/Provider/Roulette"),
        },
        {
          path: "/playGame",
          name: "game",
          component: () => import("@/views/ottogi/Game"),
        },
      ],
      meta: {
        title: "Ottogi",
        icon: "ottogi.ico",
      },
    },
  ],
};

export { paths };
