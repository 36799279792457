const state = {
  list: [],
  detail: undefined,
  detailList: [],
};

const getters = {};

const mutations = {
  handleGetDetail(state, payload) {
    state.detail = payload;
  },
};

const actions = {};

export default { state, getters, mutations, actions };
