import { getDashboardRequest } from "@/api/dashboard";

const state = {
  dashboard: {},
};

const getters = {};

const mutations = {
  handleUpdateDasboard(state, dispatch) {
    state.dashboard = dispatch;
  },
};

const actions = {
  async getDashboardAction({ commit }, data) {
    const res = await getDashboardRequest(data);
    if (res.data.status == 0) {
      commit("handleUpdateDasboard", res.data.data);
      commit("handleUpdateLoading", false);
    }
  },
};

export default { state, getters, mutations, actions };
