import {
  getStoreRequest,
  getExportStoreRequest,
  createStore,
} from "@/api/store";

const state = {
  listStore: {},
  getAllStore: {},
};

const getters = {};

const mutations = {
  handleStoreStore(state, payload) {
    state.listStore = payload;
  },
  handleUpdateAllStore(state, payload) {
    state.getAllStore = payload;
  },
};

const actions = {
  async getAffiliateStoreAction({ commit }, data) {
    const res = await getStoreRequest(data);
    if (res.data.status == 0) {
      commit("handleStoreStore", {
        list: res.data.data,
        pagination: {
          limit: res.data.limit,
          page: res.data.page,
          total: res.data.total,
        },
      });
      commit("handleUpdateLoading", false);
    } else {
      commit("handleUpdateLoading", false);
    }
  },

  async getAllAffiliateStoreAction({ commit }, data) {
    const res = await getStoreRequest(data);
    commit("handleUpdateAllStore", res.data.data);
  },

  async getExportFileStore({ commit }) {
    const res = await getExportStoreRequest();
    commit("handleUpdateLoading", false);
    return res;
  },

  async createStoreAction({ commit }, data) {
    const res = await createStore(data);
    return res;
  },
};

export default { state, getters, mutations, actions };
