import { createRouter, createWebHistory } from "vue-router";
import { paths } from "@/routes/path/path";
import store from "@/store";
import { getCustomPage } from "@/api/customPage/request";

let rt;
let res = null;
// try {
//   res = await getCustomPage();
//   store.state.customPage.styles = res?.data;
//
//   rt = paths[res.data.templete];
// } catch (error) {
//   rt = paths["Fortunez"];
// }

// rt = paths["Fortunez"];
// rt = paths["Sydney"];

switch (process.env.VUE_APP_SERVICE_TYPE) {
  case "FORTUNEZ":
    rt = paths["Fortunez"];
    break;
  case "SYDNEY":
    rt = paths["Sydney"];
    break;
  default:
    rt = paths["Fortunez"];
    break;
}

const routes = rt;

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

router.beforeEach((to, from, next) => {
  // document.title = res.data?.front_title || res.data?.templete || "EDGames";
  const link = document.querySelector("[rel='icon']");
  link.setAttribute("href", to.meta.icon);
  document.title = to.meta.title;
  next();
});

router.afterEach(() => {
  const element = document.getElementById("header_top");
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
});

export default router;
