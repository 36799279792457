import { AuthRequest } from "@/api/axios";
import queryString from "query-string";

export const getDashboardRequest = async (params) => {
  const res = await AuthRequest({
    url: `/api/dashboard${params ? `?${queryString.stringify(params)}` : ""}`,
    method: "GET",
  });
  return res;
};
