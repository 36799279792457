const state = {
  isActiveSidebar: false,
};

const getters = {};

const mutations = {
  handleActiveSidebar(state, payload) {
    state.isActiveSidebar = payload;
  },
};

const actions = {};

export default { state, getters, mutations, actions };
