<template>
  <div class="w-100 h-100 position-relative">
    <div
      @wheel.prevent
      v-if="isLoading"
      class="d-flex justify-center align-center live-casino w-100 h-100"
    >
      <div class="loading w-100 d-flex justify-center">
        <Loading />
      </div>
    </div>
    <div @wheel.prevent v-if="isLoading" class="w-100 h-100 live-opacity"></div>
    <router-view />
    <Toast />
  </div>
  <!-- <router-view />
  <Toast /> -->
</template>

<script setup>
import Toast from "@/components/fortunez/Toast";
import Loading from "@/components/fortunez/Loading";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const isLoading = computed(() => store.state.auth.loading);
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wrap {
  padding: 0 40px;
  max-width: 1360px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.wrap-custom {
  max-width: 1920px;
  margin: 0 auto;
}

.live-opacity {
  opacity: 0.5;
  background-color: #1c2529;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.live-casino {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn_primary {
  background: var(--cms-primary-color);
  border: 1px solid var(--cms-primary-color);
  border-radius: 8px;
  color: #fff;
  padding: 5px 30px;
  height: 36px;

  &:hover {
    background: #fff;
    color: var(--cms-primary-color);
  }
}
.text-error {
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

:root {
  --cms-primary-color: #56b8ff;
  --cms-primary-text-color: #464255;
}

.swal2-shown {
  padding: 0 !important;
}

.pagination {
  .v-pagination__item,
  .v-pagination__first,
  .v-pagination__prev,
  .v-pagination__next,
  .v-pagination__last {
    margin: 4px;
  }

  .v-pagination .v-btn {
    width: 32px;
    height: 32px;
  }

  .v-pagination__item--is-active {
    .v-btn__overlay {
      background: #32373d;
      border-radius: 8px;
      opacity: 1;
      z-index: 0;
    }

    .v-btn__content {
      color: #fff;
      z-index: 1;
    }
  }
}

@media (max-width: 475px) {
  .pagination {
    .v-pagination__item,
    .v-pagination__first,
    .v-pagination__prev,
    .v-pagination__next,
    .v-pagination__last {
      margin: 3px;
    }
    .v-pagination .v-btn {
      width: 26px;
      height: 26px;
    }
    .v-pagination__item--is-active {
      .v-btn__overlay {
        background: #2d3748;

        border-radius: 8px;
        opacity: 1;
        z-index: 0;
      }
      .v-btn__content {
        color: #fff;
        z-index: 1;
        font-size: 10px;
      }
    }
  }
}
</style>
