import { AuthRequest } from "@/api/axios";
import queryString from "query-string";

export const getPaymentHistoryRequest = async (params) => {
  const res = await AuthRequest({
    url: `/api/payment-history${
      params ? `?${queryString.stringify(params)}` : ""
    }`,
    method: "GET",
  });
  return res;
};
